import { urls, TYPE } from 'Reducers/faq';

export const getFaq = (categoryId, type, filters = {}, sessionFeatures = []) => (dispatch, getState, { post }) => {
    const state = getState();

    const { categories, locations } = state;
    const category = categories?.elements[categoryId];
    const parentCategoryId = category?.parent_id === 0 ? category?.id : category?.parent_id;
    const flag = (sessionFeatures || [])?.includes('olxin-4209');

    let level = null;

    if (locations?.selectedLocation?.type === 'CITY') {
        level = 'location_l3';
    }
    else if (locations?.selectedLocation?.type === 'COUNTRY') {
        level = 'location_l1';
    }

    const location = flag ? {
        level,
        name: locations?.selectedLocation?.name
    } : locations?.selectedLocation?.name;

    const actionType = `ELEMENT_${TYPE}`;
    const url = flag ? urls.getFaqV2() : urls.getFaq(parentCategoryId);

    return dispatch(post(url, actionType, {
        ...(flag ? { categoryId: category?.id } : {}),
        filters,
        location,
        type
    }));
};

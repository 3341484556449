import { MAIN_CATEGORY } from 'Constants/categories';
import { FAQS } from 'Constants/faq';

const TOP_CONTENT = 3;
const L3_LOCATION = 'CITY';

function getTopContent(input) {
    const data = [...input];
    const last = data.pop();
    const result = data.length === 0 ? last : `${data.join(', ') } and ${ last}`;

    return result || '';
}

function getFormattedNumber(intl, count) {
    return intl.formatNumber(count);
}

function getCategoryCount(categoryFilters, intl, { id, key }) {
    const count = categoryFilters?.sub_categories?.find(b => {
        if (key) {
            return b?.key === key;
        }

        if (id) {
            return b?.id === id;
        }

        return false;
    })?.count || 0;

    return count ? getFormattedNumber(intl, count) : undefined;
}

function getTotalCount(categoryFilters, intl) {
    const count = getTotalInventoryForCategory(categoryFilters);

    return count ? getFormattedNumber(intl, count) : undefined;
}

function getSelectedFilter(selectedFilters, key) {
    return selectedFilters?.find(f => f.filterId === key);
}

export function getTotalInventoryForCategory(categories) {
    if (categories && categories?.sub_categories) {
        return categories?.sub_categories?.reduce((acc, curr) => (acc += curr?.count || 0), 0);
    }

    return 0;
}

export function getContentForBikesCategory(
    categoryId,
    parentId,
    {
        categoryFilters,
        intl,
        locations,
        selectedFilters,
        selectedLocation,
        sessionFeatures,
        topModels
    }
) {
    const flag = (sessionFeatures || [])?.includes('olxin-4209');
    const cat = getType(categoryId);
    const TYPE = flag ? FAQS[MAIN_CATEGORY[cat]] : FAQS[MAIN_CATEGORY.BIKES];
    const make = getSelectedFilter(selectedFilters, 'make');
    const model = getSelectedFilter(selectedFilters, 'model');
    const popular_areas = getTopContent(getPopularLocations(locations) || []);

    const content = {
        number_of_bicycles: getCategoryCount(categoryFilters, intl, { key: 'bicycles' }),
        number_of_bikes: getTotalCount(categoryFilters, intl),
        number_of_scooters: getCategoryCount(categoryFilters, intl, { key: 'scooters' }),
        make: make?.label || undefined,
        model: model?.label || undefined,
        top_models: getTopContent((topModels || []).reduce((acc, curr) => acc.push(curr.name) && acc, [])),
        ...(flag ? { popular_areas } : {})
    };

    const isMakeFilterApplied = Boolean(make?.id);
    const isModelFilterApplied = Boolean(model?.id);

    let type;

    if (flag) {
        if (selectedLocation?.type === L3_LOCATION) {
            if (categoryId === MAIN_CATEGORY.MOTORCYCLES || categoryId === MAIN_CATEGORY.SCOOTERS) {
                if (isMakeFilterApplied) {
                    type = TYPE.LOCATION_L3_BRAND;
                }
                else if (isModelFilterApplied) {
                    type = TYPE.LOCATION_L3_MODEL;
                }
                else {
                    type = TYPE.LOCATION_L3;
                }
            }
            else if (categoryId === MAIN_CATEGORY.BICYCLES) {
                if (isMakeFilterApplied) {
                    type = TYPE.LOCATION_L3_BRAND;
                }
                else {
                    type = TYPE.LOCATION_L3;
                }
            }
            else {
                type = TYPE.LOCATION_L3;
            }
        }
        else if (selectedLocation?.type === 'COUNTRY') {
            type = TYPE.LOCATION_L1;
        }
    }
    else if (selectedLocation?.type === L3_LOCATION) {
        if (categoryId === parentId) {
            type = TYPE.CATEGORY_L1_LOCATION_L3;
        }
        else if (isModelFilterApplied) {
            type = TYPE.MODEL_CATEGORY_L2_LOCATION_L3;
        }
        else if (isMakeFilterApplied) {
            type = TYPE.BRAND_CATEGORY_L2_LOCATION_L3;
        }
        else {
            type = TYPE.CATEGORY_L2_LOCATION_L3;
        }
    }
    else {
        type = TYPE.GENERAL;
    }

    return { content, type };
}

export function getContentForElectronicsCategory(
    category,
    parentId,
    {
        categoryFilters,
        intl,
        minPrice,
        selectedLocation
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.ELECTRONICS];
    const topTypes = getTopContent(categoryFilters?.sub_categories
        ?.sort((a, b) => b.count - a.count)
        .map(i => i.name)
        .slice(0, TOP_CONTENT) || []);
    const totalNumber = category.id === parentId
        ? getTotalCount(categoryFilters, intl)
        : getCategoryCount(categoryFilters, intl, { id: category.id });

    const content = {
        number_of_items: totalNumber,
        top_models: topTypes,
        type: category.name,
        starting_price: intl.formatNumber(minPrice || 0)
    };

    const categoryContent = category.id === parentId
        ? TYPE.CATEGORY_L1_LOCATION_L3
        : TYPE.CATEGORY_L2_LOCATION_L3;

    return {
        content,
        type: selectedLocation?.type === L3_LOCATION ? categoryContent : ''
    };
}

function getPopularLocations(locations) {
    if (locations && locations?.length > 0 && locations[0].selected) {
        return locations[0]?.list?.sort((a, b) => b.count - a.count).map(i => i.title || '').slice(0, TOP_CONTENT);
    }
    else if (locations?.[0]?.list) {
        return getPopularLocations(locations?.[0]?.list);
    }

    return [];
}

export function getContentForRealEstateCategory(
    category,
    parentId,
    {
        categoryFilters,
        intl,
        locations,
        minPrice,
        plotSize,
        selectedFilters,
        selectedLocation,
        sessionFeatures
    }
) {
    const flag = (sessionFeatures || [])?.includes('olxin-4209');
    const cat = getType(category?.id);
    const TYPE = flag ? FAQS[MAIN_CATEGORY[cat]] : FAQS[MAIN_CATEGORY.REAL_ESTATE];
    const popular_areas = getTopContent(getPopularLocations(locations) || []);
    const totalNumber = category?.id === parentId ? getTotalCount(categoryFilters, intl) : getCategoryCount(categoryFilters, intl, { id: category?.id });

    const filterFurnishing = getSelectedFilter(selectedFilters, 'furnished');
    const filterSubtype = getSelectedFilter(selectedFilters, 'subtype');
    const filterType = getSelectedFilter(selectedFilters, 'type');

    let type;

    if (flag) {
        if (selectedLocation?.type === L3_LOCATION) {
            if (category?.id === MAIN_CATEGORY.LANDS_AND_PLOTS) {
                if (filterType?.props.id === 'for-rent') {
                    type = TYPE.LOCATION_L3_FOR_RENT;
                }
                else if (filterType?.props.id === 'for-sale') {
                    type = TYPE.LOCATION_L3_FOR_SALE;
                }
                else {
                    type = TYPE.LOCATION_L3;
                }
            }
            else if (
                (
                    category?.id === MAIN_CATEGORY.SHOPS_AND_OFFICES_FOR_RENT
                    || category?.id === MAIN_CATEGORY.SHOPS_AND_OFFICES_FOR_SALE
                    || category?.id === MAIN_CATEGORY.PG_AND_GUEST_HOUSES
                ) && Boolean(filterFurnishing)
            ) {
                type = TYPE.LOCATION_L3_FURNISHING;
            }
            else if (category?.id === MAIN_CATEGORY.PG_AND_GUEST_HOUSES && Boolean(filterSubtype)) {
                type = TYPE.LOCATION_L3_SUBTYPE;
            }
            else {
                type = TYPE.LOCATION_L3;
            }
        }
    }
    else if (selectedLocation?.type === L3_LOCATION) {
        if (category.id === MAIN_CATEGORY.HOUSES_FOR_RENT) {
            type = TYPE.RENT_CATEGORY_L2_LOCATION_L3;
        }
        else if (category.id === MAIN_CATEGORY.HOUSES_FOR_SALE) {
            type = TYPE.SALE_CATEGORY_L2_LOCATION_L3;
        }
        else if (category.id === parentId) {
            type = TYPE.CATEGORY_L1_LOCATION_L3;
        }
    }

    return {
        content: {
            number: totalNumber,
            popular_areas,
            starting_price: minPrice > 0 ? intl.formatNumber(minPrice) : undefined,
            ...(flag ? {
                min_plot_size: plotSize?.min || undefined,
                max_plot_size: plotSize?.max || undefined,
                furnishing: filterFurnishing?.label || undefined,
                subtype: filterSubtype?.label || undefined
            } : {})
        },
        type
    };
}

export function getContentForJobsCategory(
    category,
    parentId,
    {
        categoryFilters,
        intl,
        selectedLocation
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.JOBS];
    const topTypes = categoryFilters?.sub_categories?.sort((a, b) => b.count - a.count).map(i => i.name).slice(0, TOP_CONTENT);
    const totalNumber = category.id === parentId
        ? getTotalCount(categoryFilters, intl)
        : getCategoryCount(categoryFilters, intl, { id: category.id });
    const categoryContent = category?.id === parentId ? TYPE.CATEGORY_L1_LOCATION_L3 : TYPE.CATEGORY_L2_LOCATION_L3;

    return {
        content: {
            category: category?.name,
            job_types: getTopContent(topTypes || []),
            popular_jobs: getTopContent(topTypes || []),
            number: totalNumber
        },
        type: selectedLocation?.type === L3_LOCATION ? categoryContent : TYPE.GENERAL
    };
}

export function getContentForMobilesCategory(
    category,
    parentId,
    {
        categoryFilters,
        selectedFilters,
        intl,
        selectedLocation,
        sessionFeatures
    }
) {
    const flag = (sessionFeatures || [])?.includes('olxin-4209');
    const cat = getType(category?.id);
    const TYPE = flag ? FAQS[MAIN_CATEGORY[cat]] : FAQS[MAIN_CATEGORY.MOBILES];
    let totalNumber = getTotalCount(categoryFilters, intl);

    if (category?.id !== parentId) {
        totalNumber = getCategoryCount(categoryFilters, intl, { id: category.id });
    }

    const make = getSelectedFilter(selectedFilters, 'make');
    const deviceType = getSelectedFilter(selectedFilters, 'type');
    const isMakeFilterApplied = Boolean(make?.id);
    const isTypeFilterApplied = Boolean(deviceType?.id);

    let type;

    if (flag) {
        if (selectedLocation?.type === L3_LOCATION) {
            if (category?.id === parentId) {
                type = TYPE.LOCATION_L3;
            }
            else if (category?.id === MAIN_CATEGORY.MOBILE_PHONES) {
                if (isMakeFilterApplied) {
                    type = TYPE.LOCATION_L3_BRAND;
                }
                else {
                    type = TYPE.LOCATION_L3;
                }
            }
            else if ((category?.id === MAIN_CATEGORY.ACCESSORIES || category?.id === MAIN_CATEGORY.TABLETS) && isTypeFilterApplied) {
                type = TYPE.LOCATION_L3_TYPE;
            }
            else {
                type = TYPE.LOCATION_L3;
            }
        }
        else if (selectedLocation?.type === 'COUNTRY') {
            type = TYPE.LOCATION_L1;
        }
    }
    else if (selectedLocation.type === L3_LOCATION) {
        if (category?.id === parentId) {
            type = TYPE.CATEGORY_L1_LOCATION_L3;
        }
        else if (isMakeFilterApplied) {
            type = TYPE.BRAND_CATEGORY_L2_LOCATION_L3;
        }
        else {
            type = TYPE.CATEGORY_L2_LOCATION_L3;
        }
    }
    else {
        type = TYPE.GENERAL;
    }

    return {
        content: {
            make: make?.label || undefined,
            number: totalNumber === '0' ? undefined : totalNumber,
            ...(flag && isTypeFilterApplied ? { selected_filter: deviceType?.label || undefined } : {})
        },
        type
    };
}

export function getContentForCarsCategory(
    {
        selectedFilters,
        params: { geoID }
    }
) {
    const TYPE = FAQS[MAIN_CATEGORY.CARS];
    const make = getSelectedFilter(selectedFilters, 'make');
    const content = {
        make: make?.label || ''
    };

    const isMakeFilterApplied = Boolean(make?.id);
    let type;

    if (geoID && !isMakeFilterApplied) {
        type = TYPE.WITHOUT_BRAND_WITH_LOCATION;
    }
    else if (!geoID && isMakeFilterApplied) {
        type = TYPE.BRAND_WITHOUT_LOCATION;
    }
    else if (geoID && isMakeFilterApplied) {
        type = TYPE.BRAND_WITH_LOCATION;
    }
    else {
        type = TYPE.GENERAL;
    }

    return { content, type };
}

function getType(id) {
    let type = null;

    for (const [k, v] of Object.entries(MAIN_CATEGORY)) {
        if (id === v) {
            type = k;
            break;
        }
    }

    return type;
}

export function getFAQTypes(catId, id, { params: { geoID }, selectedFilters, selectedLocation, sessionFeatures }) {
    const cat = getType(id);
    const TYPE = FAQS[MAIN_CATEGORY[cat]];
    const flag = (sessionFeatures || [])?.includes('olxin-4209');
    const isMakeFilterApplied = Boolean(getSelectedFilter(selectedFilters, 'make')?.id);
    const isModelFilterApplied = Boolean(getSelectedFilter(selectedFilters, 'model')?.id);

    if (TYPE) {
        if (id === MAIN_CATEGORY.BIKES
            || id === MAIN_CATEGORY.ELECTRONICS
            || id === MAIN_CATEGORY.JOBS
            || id === MAIN_CATEGORY.MOBILES
        ) {
            if (selectedLocation?.type === L3_LOCATION) {
                if (isModelFilterApplied) {
                    return TYPE?.MODEL_CATEGORY_L2_LOCATION_L3;
                }
                else if (isMakeFilterApplied) {
                    return TYPE?.BRAND_CATEGORY_L2_LOCATION_L3;
                }
                else if (catId === id) {
                    return TYPE?.CATEGORY_L1_LOCATION_L3;
                }

                return TYPE?.CATEGORY_L2_LOCATION_L3;
            }

            return TYPE?.GENERAL;
        }
        else if (id === MAIN_CATEGORY.REAL_ESTATE) {
            if (selectedLocation?.type === L3_LOCATION) {
                if (catId === MAIN_CATEGORY.HOUSES_FOR_RENT) {
                    return flag ? TYPE.LOCATION_L3 : TYPE.RENT_CATEGORY_L2_LOCATION_L3;
                }
                else if (catId === MAIN_CATEGORY.HOUSES_FOR_SALE) {
                    return flag ? TYPE.LOCATION_L3 : TYPE.SALE_CATEGORY_L2_LOCATION_L3;
                }
                else if (catId === id) {
                    return flag ? TYPE.LOCATION_L3 : TYPE.CATEGORY_L1_LOCATION_L3;
                }
            }
        }
        else if (id === MAIN_CATEGORY.CARS) {
            if (geoID && !isMakeFilterApplied) {
                return TYPE.WITHOUT_BRAND_WITH_LOCATION;
            }
            else if (!geoID && isMakeFilterApplied) {
                return TYPE.BRAND_WITHOUT_LOCATION;
            }
            else if (geoID && isMakeFilterApplied) {
                return TYPE.BRAND_WITH_LOCATION;
            }

            return TYPE.GENERAL;
        }

        return TYPE.GENERAL;
    }

    return undefined;
}

function interpolateText(text = '', types = {}) {
    if (types && Object.entries(types).length > 0) {
        for (const [key, value] of Object.entries(types)) {
            if (text) {
                text = text.replaceAll(`{${key}}`, value);
            }
        }
    }

    return text;
}

function interpolateLocation(text = '', location = '') {
    if (location && text) {
        text = text.replaceAll('{location}', location);
    }

    return text ?? '';
}

export function interpolate(text, location, content) {
    const textWithLocation = interpolateLocation(text, location);

    return interpolateText(textWithLocation, content);
}

export function getTransformedMakeModels(filter) {
    let topModels = [];

    const values = filter?.render?.customConfiguration?.values || [];

    if (filter.render && values?.length > 0) {
        const {
            render: {
                customConfiguration: { valuesInfo } = {}
            } = {}
        } = filter;

        topModels = Object.values(valuesInfo || {})
            .filter(v => v?.abundance?.count)
            .sort((v1, v2) => v2.abundance.count - v1.abundance.count)
            .map(v => ({ name: v.name }))
            .slice(0, TOP_CONTENT);
    }

    return topModels;
}

export function getMinimumPrice({ items: { elements }}) {
    let min = 0;

    for (const val of Object.values(elements || {})) {
        const minVal = min > val?.price?.value?.raw ? val.price.value.raw : min;

        min = min === 0 ? (val?.price?.value?.raw || 0) : minVal;
    }

    return min;
}

export function getMultipleFilterSelected(selectedFilters) {
    return selectedFilters?.filter(i => i.filterId === 'make')?.length > 1
    || selectedFilters?.filter(i => i.filterId === 'model')?.length > 1
    || selectedFilters?.filter(i => i.filterId !== 'make' && i.filterId !== 'model')?.length > 1;
}

export function getPlotSize({ items: { elements }}) {
    let min = 0;
    let max = 0;

    for (const { parameters } of Object.values(elements || {})) {
        const yd = parseInt((parameters || [])?.find(p => p?.key === 'yd')?.value || '0', 10);
        const minVal = min > yd ? yd : min;
        const maxVal = max < yd ? yd : max;

        min = min === 0 ? yd : minVal;
        max = max === 0 ? yd : maxVal;
    }

    return { min, max };
}
